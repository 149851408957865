<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">参会人员报名审核</h2>
        <div class="RightDiv">
          <div class="firDiv">
            <el-select v-model="auditStatus" clearable placeholder="审核状态" @change="statusChange(auditStatus)">
              <el-option v-for="item in auditSelect" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="sex" label="性别" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.sex == 0">男</span>
              <span  v-else-if="scope.row.sex == 1">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="workUnit" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="duties" label="职务" align="center">
          </el-table-column>
          <el-table-column prop="participateType" label="角色" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="email" label="邮箱" align="center">
          </el-table-column>
          <el-table-column prop="idCard" label="身份证号" align="center">
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.status=='0'">待审核</div>
              <div v-else-if="scope.row.status=='1'">审核成功</div>
              <div v-else-if="scope.row.status=='2'">审核失败</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template style="display: flex" slot-scope="scope">
              <el-button v-if="scope.row.status == 0" size="mini" type="primary" @click="auditSuccess(scope.row)">通过</el-button>
              <el-button v-if="scope.row.status == 0" size="mini" type="danger" @click="revStat(scope.row)">拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>

        <el-dialog title="驳回原因" :visible.sync="revsuggdialogVisible" width="400px" :before-close="revsuggdialogVisibleClose">
          <div class="revSty">
            <el-input v-model="finReje" placeholder="请填写驳回原因"></el-input>
            <el-button type="primary" style="margin-top: 20px" @click="auditReject()">提交</el-button>
          </div>
        </el-dialog>
      </el-main>
    </el-container>


  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      auditId:'',
      finReje:'',
      revsuggdialogVisible:false,
      auditSelect:[
        {name:'待审核',id:0},
        {name:'审核成功',id:1},
        {name:'审核失败',id:2}
      ],
      auditStatus:0,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: []
    };
  },
  created() {
    this.getAuditList();
  },
  methods: {
    revsuggdialogVisibleClose(){
      this.revsuggdialogVisible=false
      this.auditId=''
      this.finReje=''
    },
    statusChange(val){
      this.pageNum = 1;
      this.auditStatus=val
      this.getAuditList();
    },
    change(e) {
      this.$forceUpdate(e);
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.getAuditList();
    },
    revStat(row){
      this.auditId=row.id
      this.revsuggdialogVisible=true
    },
    auditSuccess(row){
      this.updateStatus(row.id,1,null);
    },
    auditReject(){
      this.updateStatus(this.auditId,2,this.finReje);
    },
    async updateStatus(id,status,failMsg){

      if (status == 1){
        const confirmResult = await this.$confirm("确认审核通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);

        if (confirmResult !== "confirm") {
          return this.$message.info("取消审核");
        }
      }

      let data = _qs.stringify({
        id:id,
        status:status, //1通过 2拒绝
        failMsg:failMsg // 失败原因
      });
      let { data: res } = await this.$axios.updateAttendAuditStatus(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.auditStatus=0;
        this.revsuggdialogVisibleClose()
        this.getAuditList();
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async getAuditList() {
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),//分会场id
        status:this.auditStatus,//状态 0 未审核 1审核成功 2 审核失败
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
      });
      let { data: res } = await this.$axios.attendRegisterAuditList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
}

.revSty{
  text-align: center;
  .el-button{
    width:150px;
    margin-top: 20px;
  }
}
</style>
